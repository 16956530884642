<!-- //好友信息模板 -->
<template>
 <div><div class="ntMain__head flexbox"><h2 class="hdtit flex1">详细信息</h2><span class="link" @click="isShowDotsLayer=true"><i class="iconfont icon-vdots"></i></span>
     </div>
    <div class="ntMain__cont flex1 flexbox flex-col">
      <v3-scroll><div class="nt__padCnt sm">
          <div v-if="friendLs" class="nt-cellList nt-Uinfo flexbox flex-col">
            <div class="item flexbox flex-alignc vui__hairline-bottom">
                <div class="info flex1"><h2 class="title clamp1"><em class="mr-10">{{friendLs.nickname}}</em>
                   <i v-if="friendLs.sex=='male'" class="iconfont icon-male c-46b6ef"></i><i v-if="friendLs.sex=='female'" class="iconfont icon-female c-f37e7d"></i>
                  </h2>
                <p class="desc clamp1">{{friendLs.sign}}</p>
             </div>
            <div class="img" style="border-radius:4px;"><img :src="friendLs.avatar" /></div>
            </div>
            <div class="item flexbox flex-alignc vui__hairline-bottom"><div class="info flex1"><label class="lbl">设置备注信息</label></div><i class="iconfont icon-arrR c-999 fs-14"></i>
            </div>
            <div class="item flexbox flex-alignc vui__hairline-bottom"><div class="info flex1"><label class="lbl">设置权限</label></div><i class="iconfont icon-arrR c-999 fs-14"></i>
            </div>
            <router-link class="item flexbox flex-alignc vui__hairline-bottom" to="/fzone"><div class="info flex1"><label class="lbl">朋友圈</label></div><em class="vui__badge-dot mr-10" style="position:unset;"></em>
             <i class="iconfont icon-arrR c-999 fs-14"></i>
            </router-link>
            <div class="item flexbox flex-alignc flex-col"><div class="mt-10 vui__btn vui__btn-primary" style="height:32px;width:150px;"><i class="iconfont icon-send"></i> 发消息</div>
               <div class="mt-10 vui__btn vui__btn-default" style="color:#ff2e13;height:32px;width:150px;"><i class="iconfont icon-del"></i> 删除TA</div>
             </div></div>
            </div>
          </v3-scroll>
     </div>
     <v3-layer v-model="isShowDotsLayer" type="actionsheet" layerStyle="min-width:300px;"
      :btns="[{text: '设置备注和标签'},{text: '朋友权限'},{text: '把他推荐给朋友'},{text: '加入黑名单'},
         {text: '投诉'},{text: '取消', style: 'color:#aaa', click: () => isShowDotsLayer=false},
         ]"
      />
  </div>
</template>
<script>
import {onMounted, reactive, toRefs, watch} from 'vue'
import {useRoute} from 'vue-router'
const friendJSON = require('@/mock/friendlist.js')
export default {
 components: {},
  setup() {
      const route = useRoute()
     const data = reactive({isShowDotsLayer: false,
      friendLs: null,})
        onMounted(() => {getUinfo()
        })
      watch(() => route.query.fid, () => {getUinfo()
        })
        const getUinfo = () => {
         friendJSON.map((item) => {
            item.list.map((item2) => {
              if(item2.fid == route.query.fid) {
             data.friendLs = item2
           }
         })
         })
      }
     return {...toRefs(data),
     }
 }
}
</script>
